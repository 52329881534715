import * as yup from "yup";

import {
  labNumberFormat,
  nullableString,
  optionalDate,
  optionalPragueClassification,
  optionalString,
  participantIdBest4Format,
  recordNumberFormat,
  requiredBoolean,
  requiredDate,
  requiredForAllSurveillanceForms,
  requiredForBest4Forms,
  requiredForLegacyRefluxForms,
  requiredForLegacySurveillanceForms,
  requiredIfNoPatientIdentifier,
  requiredString,
  requiredWhenBloodOnSponge,
  requiredWhenHadDysplasia,
  requiresTwoLettersForBest4,
} from "./ValidationRules";

/**
 * This is the core UI schema which drives client-side validation using
 * a mixture of basic rules (eg. requiredString, optionalDate) and custom
 * rules (eg. pragueClassification).
 */
export type CaseUiData = yup.InferType<typeof CaseUiSchema>;

export const CaseUiSchema = yup.object().shape({
  // Request form
  requestFormType: requiredString,

  // Device details
  labNumber: requiredString.concat(labNumberFormat),
  recordNumber: requiredString.concat(recordNumberFormat),
  deviceId: optionalString,
  specimen: requiredString,
  deviceBrand: nullableString, // Derived from specimen

  // Report recipient
  userGroupId: requiredString,
  clinician: requiredString,
  caseOrigin: requiredString,
  caseOriginName: optionalString, // Don't validate hidden inputs
  consultantName: optionalString, // as they can't be given focus

  // Patient details
  patientIdentifier: requiredString,
  patientIdentifierNotProvided: requiredBoolean,
  patientIdentifierAlternative: optionalString.concat(requiredIfNoPatientIdentifier),
  patientDateOfBirth: requiredDate,
  patientDateOfBirthIsYearOnly: requiredBoolean,
  patientSex: requiredString,
  patientFirstName: requiredString,
  patientSurname: requiredString,
  patientInitials: requiredString.concat(requiresTwoLettersForBest4),
  useRecordNumberForPatientName: requiredBoolean,
  patientResearchStudy: optionalString.concat(requiredForBest4Forms),
  patientResearchId: optionalString.concat(participantIdBest4Format),

  // Clinical information
  hasEoEDiagnosis: nullableString.concat(requiredForLegacyRefluxForms), // Until present in all new TRFs
  hasAdditionalClinicalInformation: requiredBoolean,

  // Clinical information (deprecated)
  isTakingPPI: requiredString, // Until removed from new TRFs
  smokingHistory: requiredString, // Until removed from new TRFs
  hasRefluxSymptoms: nullableString.concat(requiredForLegacyRefluxForms),
  hadEndoscopicTreatment: nullableString.concat(requiredForLegacySurveillanceForms),

  // Barrett's details
  hadDysplasia: nullableString.concat(requiredForAllSurveillanceForms),
  dysplasiaGrade: nullableString.concat(requiredWhenHadDysplasia),
  dateLastEndoscopy: optionalDate,
  circumferentialLength: optionalPragueClassification,
  maximalLength: optionalPragueClassification,

  // Procedure details
  procedureDate: requiredDate,
  practitionerName: optionalString,
  practitionerLocation: optionalString,
  useClinicianForProcedureSite: requiredBoolean,
  isBloodOnSponge: requiredString,
  numberOfFailedAttempts: requiredString,

  // Procedure details (deprecated)
  didPatientSwallow: requiredString, // Until removed from new TRFs
  didSpongeExpand: requiredString, // Until removed from new TRFs
  wasTensionOnThread: requiredString, // Until removed from new TRFs
  isDebrisOnSponge: requiredString, // Until removed from new TRFs
  takesAnticoagulants: nullableString.concat(requiredWhenBloodOnSponge), // Until removed from new TRFs

  // Lab use only
  slides: requiredString,
  dateReceived: requiredDate,
  pathologistId: requiredString,
  consultant: requiredString,
  macro: requiredString,
});
