import * as yup from "yup";

import { patterns } from "../../helpers/validation";
import { Answer } from "./FormAnswers";
import {
  isBest4Form,
  isBest4RefluxForm,
  isBest4SurveillanceForm,
  isLegacyRefluxForm,
  isLegacySurveillanceForm,
  isSurveillanceForm,
} from "./FormConditions";

export const FIELD_REQUIRED_ERROR: string = "Required";
export const PRAGUE_CLASSIFICATION_DIVISOR: number = 0.5;

yup.setLocale({
  mixed: { required: FIELD_REQUIRED_ERROR },
});

/**
 * Basic rules for many common fields. These can be extended where
 * necessary, eg. a required string that also must adhere to a
 * specific format.
 */
export const requiredString = yup.string().trim().required();
export const optionalString = yup.string().trim().default("");
export const nullableString = yup.string().trim().nullable().default(null);
export const requiredBoolean = yup.bool().defined();
export const requiredDate = yup.date().typeError(FIELD_REQUIRED_ERROR).required();
export const optionalDate = yup
  .date()
  .nullable()
  .default(null)
  .transform((date) => date ?? null);

/**
 * Conditional rules for form-specific or study-specific fields, eg.
 * patientResearchStudy is required for BEST4 cases, and hadDysplasia
 * is required for surveillance cases.
 */
export const requiredIfNoPatientIdentifier = yup
  .string()
  .default("")
  .when("patientIdentifierNotProvided", { is: true, then: () => requiredString });
export const requiredForBest4Forms = yup
  .string()
  .default("")
  .when("requestFormType", { is: isBest4Form, then: () => requiredString });
export const requiredForLegacyRefluxForms = yup
  .string()
  .nullable()
  .default(null)
  .when("requestFormType", { is: isLegacyRefluxForm, then: () => requiredString });
export const requiredForLegacySurveillanceForms = yup
  .string()
  .nullable()
  .default(null)
  .when("requestFormType", { is: isLegacySurveillanceForm, then: () => requiredString });
export const requiredForAllSurveillanceForms = yup
  .string()
  .nullable()
  .default(null)
  .when("requestFormType", { is: isSurveillanceForm, then: () => requiredString });
export const requiredWhenHadDysplasia = yup
  .string()
  .nullable()
  .default(null)
  .when("hadDysplasia", { is: Answer.YES, then: () => requiredString });
export const requiredWhenBloodOnSponge = yup
  .string()
  .nullable()
  .default(null)
  .when("isBloodOnSponge", { is: Answer.YES, then: () => requiredString });

/**
 * Formatting rules for special fields. These can be used on their
 * own or used to extend basic rules.
 */
export const labNumberFormat = yup
  .string()
  .length(10, "Must be exactly 10 characters, e.g. 22CYT00302")
  .matches(patterns.labNumber, "Must be valid format, e.g. 22CYT00302")
  .required();
export const recordNumberFormat = yup
  .string()
  .length(8, "Must be exactly 8 characters, e.g. 22P12345")
  .matches(patterns.recordNumber, "Must be valid format, e.g. 22P12345")
  .required();
export const participantIdBest4Format = yup
  .string()
  .concat(requiredForBest4Forms)
  .when("requestFormType", {
    is: isBest4RefluxForm,
    then: (schema) =>
      schema.matches(
        patterns.participantIdBest4Reflux,
        "Must be valid format, e.g. SCR-123-456"
      ),
  })
  .when("requestFormType", {
    is: isBest4SurveillanceForm,
    then: (schema) =>
      schema.matches(
        patterns.participantIdBest4Surveillance,
        "Must be valid format, e.g. SUR-ABC-123"
      ),
  });

export const requiresTwoLettersForBest4 = yup
  .string()
  .when("requestFormType", {
    is: isBest4Form,
    then: (schema) => schema.matches(patterns.initials, "Must be 2 letters"),
  })
  .required();
export const optionalPragueClassification = yup
  .number()
  .nullable()
  .default(null)
  .min(0, "Must not be a negative number")
  .max(20, "Must not be greater than 20")
  .test(
    "is-segment-length-divisible",
    `Must be divisible by ${PRAGUE_CLASSIFICATION_DIVISOR}`,
    (num) => {
      // Empty values are allowed, otherwise must be divisible by 0.5
      return (
        num === null || num === undefined || num % PRAGUE_CLASSIFICATION_DIVISOR === 0
      );
    }
  )
  .transform((num) => (num === "" || isNaN(num) ? null : num));
