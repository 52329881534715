import { format } from "date-fns";

import { FormSelectOption } from "../components/forms/FormSelect";

export const SITE_TITLE = "LabKit - Cyted Health";

// Placeholder for dropdown list values not found in state.metadata.limsOptions
export const CHECK_LIMS = "[Check LIMS]";

// Current year as two digits for placeholder attributes
export const CURRENT_YEAR = format(new Date(), "yy");

export const getInitialsFromPatientName = (
  firstName: string,
  surname: string
): string => {
  return firstName.trim().substring(0, 1) + surname.trim().substring(0, 1);
};

export const getFormSelectOptionsFromArray = (options: string[]): FormSelectOption[] => {
  return options.map((item) => ({ label: item, value: item }));
};
