import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../../components/forms/Fieldset";
import { FormMode, setValueConfig } from "../../FormWrapper";
import {
  DidPatientSwallow,
  DidSpongeExpand,
  IsDebrisOnSponge,
  TakesAnticoagulants,
  WasTensionOnThread,
} from "../../fields/deprecated";
import {
  IsBloodOnSponge,
  NumberOfFailedAttempts,
  PractitionerLocation,
  PractitionerName,
  ProcedureDate,
} from "../../fields/procedure";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer } from "../../schema/FormAnswers";

export const TEST_ID_FORM_IDEAL_PROCEDURE_BUTTON = "FormIdealProcedureButton";

interface ProcedureDetailsProps {
  formMode: FormMode;
  formLocked: boolean;
}

const ProcedureDetails = ({
  formMode,
  formLocked,
}: ProcedureDetailsProps): JSX.Element => {
  const { setValue } = useFormContext<CaseUiData>();

  // Shortcut button for procedures with no issues to save clicks
  const NoIssuesButton = (): JSX.Element | null => {
    if (formLocked) return null;
    return (
      <button
        type="button"
        disabled={formLocked}
        className="button is-small is-link is-light ml-3"
        data-testid={TEST_ID_FORM_IDEAL_PROCEDURE_BUTTON}
        onClick={() => {
          setValue("didPatientSwallow", Answer.YES, setValueConfig);
          setValue("didSpongeExpand", Answer.YES, setValueConfig);
          setValue("wasTensionOnThread", Answer.YES, setValueConfig);
          setValue("isDebrisOnSponge", Answer.NO, setValueConfig);
          setValue("isBloodOnSponge", Answer.NO, setValueConfig);
          setValue("takesAnticoagulants", null, setValueConfig);
        }}
      >
        <FontAwesomeIcon icon={faBolt} className="mr-2" />
        No issues
      </button>
    );
  };

  return (
    <Fieldset title="Procedure details" disabled={formLocked} addon={<NoIssuesButton />}>
      <div className="columns">
        <div className="column is-half">
          <DidPatientSwallow />
          <DidSpongeExpand />
          <WasTensionOnThread />
          <IsDebrisOnSponge />
          <IsBloodOnSponge />
          <TakesAnticoagulants />
        </div>
        <div className="column is-half">
          <PractitionerName />
          <PractitionerLocation />
          <div className="columns is-mobile">
            <div className="column is-half">
              <ProcedureDate formMode={formMode} />
            </div>
          </div>
          <NumberOfFailedAttempts />
        </div>
      </div>
    </Fieldset>
  );
};

export default ProcedureDetails;
