import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from "aws-amplify/auth";
import classNames from "classnames";
import { QRCodeSVG } from "qrcode.react";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "../components/forms/Box";
import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";
import { AppDispatch, RootState } from "../store";
import { setMfaEnabled } from "../store/authSlice";
import { selectEnvironment } from "../store/metadataSlice";

export const TEST_ID_MFA_SETUP_QR_CODE = "MfaSetupQrCode";
export const TEST_ID_MFA_SETUP_VALIDATION_ERROR = "MfaSetupValidationError";

const UserProfile = (): JSX.Element => {
  // Redux
  const { username, isMfaEnabled } = useSelector((state: RootState) => state.auth);
  const environment = useSelector(selectEnvironment);
  const dispatch = useDispatch<AppDispatch>();

  // Local state
  const [qrCode, setQrCode] = useState<string>("");
  const [qrError, setQrError] = useState<boolean>(false);
  const [challengeAnswer, setChallengeAnswer] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  // Page title
  const pageTitle = "User settings";

  // Friendly name displayed in authenticator app
  const getIssuer = (): string => {
    switch (environment) {
      case "local-development":
      case "staging":
        return "LabKit (staging)";
      case "pre-production":
        return "LabKit (pre-prod)";
      default:
        return SITE_TITLE;
    }
  };

  useEffect(() => {
    const getQrCode = async (): Promise<void> => {
      try {
        const { sharedSecret: secret } = await setUpTOTP();
        setQrCode(`otpauth://totp/${username}?secret=${secret}&issuer=${getIssuer()}`);
      } catch (error) {
        console.error(error);
        setQrError(true);
      }
    };
    document.title = `${pageTitle} | ${SITE_TITLE}`;
    getQrCode();
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setBusy(true);
    setFormError("");
    try {
      await verifyTOTPSetup({ code: challengeAnswer });
      await updateMFAPreference({ totp: "ENABLED" });
      dispatch(setMfaEnabled(true));
    } catch (error) {
      setBusy(false);
      setFormError(error instanceof Error ? error.message : "Failed to verify code");
    }
  };

  const MFASetup = (): JSX.Element => {
    return (
      <div className="content">
        <p>
          Enable two-factor authentication to add an extra layer of security to your
          account.
        </p>
        <p className="mb-5">
          If you don’t already have one, install a one-time password authenticator app on
          your mobile device. We recommend{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.google.com/accounts/answer/1066447"
          >
            Google Authenticator
          </a>
          .
        </p>
        <h4>1. Scan the QR code</h4>
        <div className="mb-5">
          {qrError ? (
            <p className="has-text-danger">Failed to retrieve QR code</p>
          ) : (
            <>
              <p>Use your one-time password authenticator app to scan this QR code.</p>
              {!!qrCode && (
                <QRCodeSVG value={qrCode} data-testid={TEST_ID_MFA_SETUP_QR_CODE} />
              )}
            </>
          )}
        </div>
        <h4>2. Enter your authentication code</h4>
        <p>
          After scanning the QR code, enter the app-generated authentication code to
          complete setup.
        </p>
        <form className="field has-addons mb-0" onSubmit={handleSubmit}>
          <p className="control">
            <input
              required
              autoFocus
              type="text"
              maxLength={6}
              pattern="\d{6}"
              id="challengeAnswer"
              value={challengeAnswer}
              className="input"
              style={{ width: "10rem" }}
              placeholder="6-digit code"
              onChange={(e) => setChallengeAnswer(e.target.value)}
            />
          </p>
          <p className="control">
            <button
              type="submit"
              className={classNames("button is-link", { "is-loading": busy })}
            >
              Confirm
            </button>
          </p>
        </form>
        {!!formError && (
          <p className="has-text-danger" data-testid={TEST_ID_MFA_SETUP_VALIDATION_ERROR}>
            {formError}
          </p>
        )}
      </div>
    );
  };

  const MFAComplete = (): JSX.Element => {
    return (
      <div className="content">
        <p>
          Two-factor authentication is enabled for your account.
          <FontAwesomeIcon icon={faCheck} color="green" className="ml-2" />
        </p>
        <p>
          Contact an administrator if you need to disable or reset two-factor
          authentication.
        </p>
      </div>
    );
  };

  return (
    <div className="container is-max-desktop">
      <PageHeader title={pageTitle} subtitle={username} />
      <Box title="Security">{isMfaEnabled ? <MFAComplete /> : <MFASetup />}</Box>
    </div>
  );
};

export default UserProfile;
