import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { RequestFormProps } from "../FormComponent";
import { setValueConfig } from "../FormWrapper";
import { BarrettsDetails, DeviceDetails, LabUseOnly, ReportRecipient } from "../blocks";
import { ProcedureDetails } from "../blocks/deprecated";
import { Best4ParticipantId, Best4StudyArm } from "../fields/best4";
import { HasAdditionalClinicalInformation } from "../fields/clinical";
import {
  PatientDateOfBirth,
  PatientIdentifier,
  PatientInitials,
  PatientSex,
} from "../fields/patient";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Best4SurveillanceStudyArms } from "../schema/RequestForms";

const TRF_4_Version_1 = ({
  formMode,
  formLocked,
  limsOptions: { specimens, clinicians, caseOrigins, consultants },
}: RequestFormProps): JSX.Element => {
  const { register, unregister, getValues, setValue } = useFormContext<CaseUiData>();

  useEffect(() => {
    // Discard any fields in form state which aren't used in this form
    unregister([
      "useRecordNumberForPatientName",
      "useClinicianForProcedureSite",
      "patientIdentifierAlternative",
      "patientIdentifierNotProvided",
      "hasRefluxSymptoms",
      "hasEoEDiagnosis",
    ]);
    // Refresh the (hidden) patient name fields to use only initials
    updatePatientName();
  }, []);

  const updatePatientName = (): void => {
    const initials = getValues("patientInitials").trim();
    const firstName = initials.substring(0, 1);
    const surname = initials.substring(1, 2);
    setValue("patientFirstName", firstName, setValueConfig);
    setValue("patientSurname", surname, setValueConfig);
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <DeviceDetails disabled={formLocked} specimens={specimens} />
        </div>
        <div className="tile is-parent">
          <ReportRecipient
            disabled={formLocked}
            clinicians={clinicians}
            caseOrigins={caseOrigins}
          />
        </div>
      </div>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <Fieldset
            title="Participant details"
            className="tile is-child"
            disabled={formLocked}
          >
            <div className="columns">
              <div className="column is-8">
                <PatientIdentifier />
              </div>
              <div className="column is-4">
                <PatientInitials help="2 letters only" onChange={updatePatientName} />
                <input
                  type="hidden"
                  id="patientFirstName"
                  {...register("patientFirstName")}
                />
                <input
                  type="hidden"
                  id="patientSurname"
                  {...register("patientSurname")}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-8">
                <PatientDateOfBirth />
              </div>
              <div className="column is-4">
                <PatientSex />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="tile is-parent">
          <Fieldset title="BEST4" className="tile is-child" disabled={formLocked}>
            <Best4ParticipantId help="e.g. SUR-ABC-123" />
            <Best4StudyArm disabled={formLocked} studyArms={Best4SurveillanceStudyArms} />
          </Fieldset>
        </div>
      </div>

      <Fieldset title="Clinical information" disabled={formLocked}>
        <BarrettsDetails disabled={formLocked} />
        <HasAdditionalClinicalInformation />
      </Fieldset>

      <ProcedureDetails formMode={formMode} formLocked={formLocked} />

      <LabUseOnly
        forceP53
        formMode={formMode}
        disabled={formLocked}
        consultants={consultants}
      />
    </>
  );
};

export default TRF_4_Version_1;
