export enum DeviceBrand {
  ENDOSIGN = "EndoSign",
  CYTOSPONGE = "Cytosponge",
}

export enum SlideOptions {
  HE_TFF3 = "H+E and TFF3",
  HE_TFF3_P53 = "H+E, TFF3 and p53",
}

export type AllowedSlides = "TFF3" | "HAndE" | "P53";

export enum Answer {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  YES = "Yes",
  NO = "No",
  UNKNOWN = "Don’t know",
  UNANSWERED = "Not provided",
  NEVER = "Never",
  FORMER = "Former",
  CURRENT = "Current",
  LOW = "Low",
  HIGH = "High",
  INDEFINITE = "Indefinite",
  NONE = "None",
  ONE = "1",
  TWO = "2",
}

export const CaseSchemaOptions = {
  patientSex: [Answer.MALE, Answer.FEMALE, Answer.OTHER],
  yesNoUnknown: [Answer.YES, Answer.NO, Answer.UNKNOWN],
  yesNoUnanswered: [Answer.YES, Answer.NO, Answer.UNANSWERED],
  yesNoUnknownUnanswered: [Answer.YES, Answer.NO, Answer.UNKNOWN, Answer.UNANSWERED],
  smokingHistory: [Answer.NEVER, Answer.FORMER, Answer.CURRENT, Answer.UNANSWERED],
  dysplasiaGrade: [
    Answer.LOW,
    Answer.HIGH,
    Answer.INDEFINITE,
    Answer.UNKNOWN,
    Answer.UNANSWERED,
  ],
  numberOfFailedAttempts: [Answer.NONE, Answer.ONE, Answer.TWO],
  slides: Object.values(SlideOptions),
};

// SlideOptions displayed in UI, AllowedSlides expected by API
export const SlidesMap: { [key in SlideOptions]: AllowedSlides[] } = {
  [SlideOptions.HE_TFF3]: ["HAndE", "TFF3"],
  [SlideOptions.HE_TFF3_P53]: ["HAndE", "TFF3", "P53"],
};
