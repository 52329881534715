import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const NumberOfFailedAttempts = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="numberOfFailedAttempts"
      label="Number of failed attempts"
      options={CaseSchemaOptions.numberOfFailedAttempts}
      error={formState.errors.numberOfFailedAttempts}
      {...register("numberOfFailedAttempts")}
    />
  );
};

export default NumberOfFailedAttempts;
